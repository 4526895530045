import {
	DR_LITE,
	DR_STR,
	DR_FULL,
	GLAUCOMA_CDR,
	GLAUCOMA_CDR_STR,
	AMD_LITE,
	AMD_STR,
	AMD_FINAL,
	AMD_FINAL_STR,
	GC_LITE,
	GC_LITE_STR,
	DR_FULL_STR,
	DME, MEDIA_OPACITY, DME_STR, MEDIA_OPACITY_STR, DISC_ASYMMETRY, DISC_ASYMMETRY_STR
} from "../constants/constants";
import {
    DIABETES_THERAPY_TYPE_STR,
    DIABETES_TYPE_NUM, HEALTH_DATA_TYPE_VAR,
    HEALTH_DATA_TYPE_STR,
    ME_PRESENCE_STRING_TO_NUM,
    DR_PRESENCE_STRING_TO_NUM
} from '../constants/medicalRecords';
import { DME_LEVEL_STR_ENUMS, MEDIA_OPACITY_RESULT_STR_ENUMS } from "../constants/results";

export const mapServiceToDiseaseName = (serviceType) => {
	switch (serviceType) {
		case DR_LITE:
			return DR_STR;
		case DR_FULL:
			return DR_FULL_STR;
		case GLAUCOMA_CDR:
			return GLAUCOMA_CDR_STR;
		case AMD_LITE:
			return AMD_STR;
		case AMD_FINAL:
			return AMD_FINAL_STR;
		case GC_LITE:
			return GC_LITE_STR;
		case DISC_ASYMMETRY:
			return DISC_ASYMMETRY_STR;
		case DME:
			return DME_STR;
		case MEDIA_OPACITY:
			return MEDIA_OPACITY_STR;
		default:
			return null;
	}
};

export const mapHealthDataTypeNumToStr = healthDataType => {
    return HEALTH_DATA_TYPE_STR[healthDataType];
}

export const mapHealthDataTypeNumToVarName = healthDataType => {
    return HEALTH_DATA_TYPE_VAR[healthDataType];
}

export const mapDiabetesTypeStringToNum = diabetesTypeStr => {
    return DIABETES_TYPE_NUM[diabetesTypeStr];
}

export const mapDiabetesTherapyTypeNumToStr = (diabetesTherapy, appendOn = true) => {
    let diabetesTherapyStr = '';
    // append the "On" word in front for better understanding
    if (appendOn) {
        diabetesTherapyStr += 'On ';
    }
    return diabetesTherapyStr + DIABETES_THERAPY_TYPE_STR[diabetesTherapy];
}

export const mapDRPresenceTypeStringtoNum = DRPresenceStr => {
    return DR_PRESENCE_STRING_TO_NUM[DRPresenceStr];
}

export const mapMEPresenceTypeStringtoNum = MEPresenceStr => {
    return ME_PRESENCE_STRING_TO_NUM[MEPresenceStr];
}