export const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

export const EMAIL = 'email';
export const PHONE_NUMBER = 'phone_number';

export const PHONE_REGEX_BY_COUNTRY = {
	US: /^\+1[2-9]\d{9}$/,
	AU: /^\+61(|0)[2-478](?:[ -]?[0-9]){8}$/,
	NZ: /^\+64[2-9]\d{7}$/,
	GB: /^\+44[0-9]\d{9,10}$/,
	IE: /^\+353[0-9]\d{8,9}$/,
	IN: /^\+91[0-9]\d{9}$/,
	SG: /^\+65[689]\d{7}$/,
	MY: /^\+601[0-9]\d{7,8}$/,
	DE: /^\+49\d{10,11}$/,
};

// validation error codes
export const VALIDATION_ERROR_CODES = {
	// User related
	ALREADY_EXISTED: 101,
	REFRESH_TOKEN_INVALID: 103,
	PASSWORD_NOT_MATCHED: 104,
	ACCOUNT_ALREADY_ACTIVATED: 105,
	INVALID_TOKEN: 106,
	COMMON_PASSWORD: 107,
	TERMS_CONDITIONS_NOT_ACCEPTED: 108,
	INVALID_CREDENTIALS: 109,
	MISSING_FIELDS: 110,
	APPROVAL_CONFIRMATION_PENDING: 111,
	INVALID_ROLE: 112,
	INVALID_EMAIL: 113,
	INVALID_PHONE_NUMBER: 114,
	EMAIL_NOT_VERIFIED: 115,
	PHONE_NUMBER_NOT_VERIFIED: 116,
	OTP_REQUEST_TOO_FREQUENTLY: 117,

	// SMS related
	USER_DAILY_SMS_LIMIT_REACHED: 150,
	ORG_DAILY_SMS_LIMIT_REACHED: 151,

	// Eye screening related
	EYE_SCREENING_CREATE_FAILED: 201,
	IMAGE_UPLOAD_DENIED: 202,
	INVALID_LATERALITY: 203,
	IMAGE_COUNTS_LIMIT: 204,
	EYE_SCREENING_STATUS_INVALID: 205,
	EYE_SCREENING_NOT_FOUND: 206,
	EYE_SCREENING_IMAGE_NOT_FOUND: 207,
	REPORT_NOT_READY: 208,
	EYE_SCREENING_OPERATOR_INVALID: 209,
	EYE_SCREENING_IMAGE_COUNT_INVALID: 210,
	EYE_SCREENING_IMAGE_GRADING_SERVICE_NOT_FOUND: 211,
	EYE_SCREENING_REPORT_NOT_FOUND: 212,

	// Service Related
	TOO_MANY_SUBMISSIONS: 301,
	INVALID_TIME_PASSED: 302,
	INVALID_DEMOGRAPHIC: 303,

	// Audit related
	OBJECT_NOT_FOUND: 401,
	INVALID_OPERATION: 402,

	// PAYMENT related
	PAYMENT_NOT_ENOUGH_UNIT: 500,
	CLIENT_NOT_FOUND: 501,
	SUBSCRIPTION_NOT_FOUND: 502,
	SUBSCRIPTION_NOT_ACTIVE_OR_TRIALING: 503,
	SUBSCRIPTION_PAST_DUE: 504,
	ADD_ON_NOT_ACTIVE: 505,
	ADD_ON_ACTIVE: 506,

	// Screening service related
	DISC_OR_CUP_UNDETECTABLE: 601,
	CDR_CALCULATION_FAILED: 602,

	// lambda function sys error
	LAMBDA_FUNCTION_ERROR: 700,
};

// Maximum length of comments allowed
export const COMMENT_MAX_LENGTH = 512
