import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import LargeButton from '../atoms/LargeButton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import IconHeading from '../molecules/IconHeading';
import LargeButtonSecondary from '../atoms/LargeButtonSecondary';
import { minWidth } from 'styled-system';
const PREFIX = 'AlertDialog';

const AlertDialog = (props) => {
	const handleClose = (event, reason) => {
		if (props.disableBackdropClick && reason === 'backdropClick') {
			return false;
		}

		if (props.disableEscapeKeyDown && reason === 'escapeKeyDown') {
			return false;
		}

		if (typeof props.onCancel === 'function') {
			props.onCancel();
		}
	};

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullScreen={props.isFullScreen}
		>
			<DialogTitle id="alert-dialog-title">
				<IconHeading title={props.title} icon={props.icon} />
			</DialogTitle>
			<DialogContent sx={{ minWidth: '400px', padding: '0 24px' }}>
				<DialogContentText id="alert-dialog-description" style={{ maxWidth: '360px' }}>
					{props.description}
				</DialogContentText>
				<DialogContentText id="alert-dialog-description" style={{ maxWidth: '350px', textAlign: 'center' }}>
					{props.description2}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ minWidth: '400px', padding: '24px' }}>
				<Grid container justifyContent="center" spacing={2}>
					<Grid item xs={12} sm={6}>
						{props.cancelLabel ? (
							<LargeButtonSecondary
								fullWidth
								noMargin
								onClick={props.isAuditExamPage ? props.onConfirm : props.onCancel}
								color="primary"
								autoFocus
							>
								{props.isAuditExamPage ? props.confirmLabel : props.cancelLabel}
							</LargeButtonSecondary>
						) : null}
					</Grid>
					<Grid item xs={12} sm={6}>
						<LargeButton
							width="full"
							noMargin
							onClick={props.isAuditExamPage ? props.onCancel : props.onConfirm}
							color={props.confirmButtonColor || 'blue'}
						>
							{props.isAuditExamPage ? props.cancelLabel : props.confirmLabel}
						</LargeButton>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
AlertDialog.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	cancelLabel: PropTypes.string,
	confirmLabel: PropTypes.string,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
	isOpen: PropTypes.bool.isRequired,
	disableBackdropClick: PropTypes.bool,
	isFullScreen: PropTypes.string,
	icon: PropTypes.object,
	isAuditExamPage: PropTypes.bool,
	confirmButtonColor: PropTypes.string,
};
export default AlertDialog;
