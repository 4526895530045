
//Imports for SERVICES_WITH_ICON only
import React from 'react';
import LITE from "../resources/icons/files/LITE.svg";
import FULL from "../resources/icons/files/FULL.svg";
import TELEMEDC_LOGO from "../resources/icons/files/logo.png";
import RETINA_RISK_LOGO from "../resources/icons/files/retinarisk-logo.png";

// Plaform types

export const PLATFORM_UNKNOWN = 0;
export const TELEEYE_MD_WEB = 1;
export const TELEEYE_MD_MOBILE = 2;
export const EYEQX = 3;
export const MY_DRY_EYE= 4;

export const PLATFORM_STR = {
  [PLATFORM_UNKNOWN]: "Unknown",
  [TELEEYE_MD_WEB]: "TeleEye MD Web",
  [TELEEYE_MD_MOBILE]: "TeleEye MD Mobile App",
  [EYEQX]: "EyeQx Mobile App",
  [MY_DRY_EYE]: "My DryEye Mobile App",
};

// User types
export const GP = 1;
export const PATIENT = 2;
export const OPTOMETRIST = 3;
export const OPHTHALMOLOGIST = 4;
export const ORGANISATION_ADMIN = 5;
export const TELEMEDC_ADMIN = 6;

export const ROLES_STR = {
  [GP]: "Practitioner",
  [PATIENT]: "Patient",
  [OPTOMETRIST]: "Optometrist",
  [OPHTHALMOLOGIST]: "Reviewer",
  [ORGANISATION_ADMIN]: "Organisation Admin",
  [TELEMEDC_ADMIN]: "Telemedc Admin",
};

export const ROLES_STR_SHORT = {
  [GP]: "GP",
  [PATIENT]: "Patient",
  [OPTOMETRIST]: "OPTOM",
  [OPHTHALMOLOGIST]: "OPH",
  [ORGANISATION_ADMIN]: "Org Ad",
  [TELEMEDC_ADMIN]: "TMC Ad",
};

export const ROLES_WITH_OWN_TERMS = [
  OPHTHALMOLOGIST
]

// SERVICES TYPES
export const QA = 1;
export const LR = 2;
export const DR_LITE = 3;
export const GLAUCOMA_CDR = 4;
export const DR_FULL = 5;
export const AUDIT_SVC = 6;
export const GC_LITE = 9;
export const AMD_LITE = 7; //10;
export const AMDDR = 8; // 11;
export const AMD_FINAL = 99;
export const STDR_RISK = 11;
export const DR_RISK = 10;
export const DISC_ASYMMETRY = 'disc_asymmetry';

// EXTRA AUDIT PARAMETER TYPES
export const DME = 'dme_level';
export const MEDIA_OPACITY = 'media_opacity';

// SERVICE-PACKAGE GROUPING
export const RETINO_SCAN_SERVICES = process.env.REACT_APP_AWS_REGION === 'ap-southeast-2' ?
    [DR_LITE, GC_LITE, AMD_LITE, GLAUCOMA_CDR]:
    [DR_LITE, GC_LITE, AMD_LITE]
export const RETINO_SCAN_ADV_SERVICES = process.env.REACT_APP_AWS_REGION === 'ap-southeast-2'?
    [DR_FULL]:
    [DR_FULL, GLAUCOMA_CDR]
export const ALL_SERVICES = [DR_LITE, AMD_LITE, GC_LITE, DR_FULL, GLAUCOMA_CDR, AUDIT_SVC, DR_RISK]
export const PAID_SERVICES = [STDR_RISK]

// RETINO SCAN SERVICE-PACKAGE GROUPING
export const RETINO_SCAN_PACKAGES = [[...RETINO_SCAN_SERVICES], [...RETINO_SCAN_ADV_SERVICES]]

// RETINO SCAN PACKAGE TYPE CODE
export const RETINO_SCAN = 0;
export const RETINO_SCAN_ADV = 1;
// RETINO SCAN PACKAGE NAME
export const RETINO_SCAN_STR = 'RetinoScan';
export const RETINO_SCAN_ADV_STR = 'RetinoScan Advanced';

// PACKAGE SERVICES
export const LITE_PACKAGE = 0;
export const FULL_PACKAGE = 1;

//ORDERED SERVICES
export const ORDERED_STR_TO_TYPE = {
  "ordered_dr_lite": DR_LITE,
  "ordered_dr_full": DR_FULL,
  "ordered_amd_dr": AMDDR,
  "ordered_assigned_audit": AUDIT_SVC,
  "ordered_pooled_audit": AUDIT_SVC,
  "ordered_glaucoma_cdr": GLAUCOMA_CDR,
  "ordered_amd": AMD_LITE,
  // "ordered_amd_dr": AMDDR,
  "ordered_gc_lite": GC_LITE,
}

// EXAM STATUS
export const SERVICE_NOT_PURCHASED = 0; // For services that are purchased
export const EXAM_PENDING = 1; // Available for image uploading
export const EXAM_ORDERED = 2; // Available for image uploading
export const EXAM_SUBMITTED = 3; // Available for services

//NEW OVERALL STATUSES
export const PENDING_ACTION = 3;
export const AWAITING_RESULT = 4;
export const PENDING_REVIEW = 5;
export const REPORT_READY = 6;


// SERVICES STATUS
export const DISPATCHED = 2;
export const COMPLETED = 3;

// GENDER
export const GENDER_MALE = 1;
export const GENDER_FEMALE = 2;
export const GENDER_OTHERS = 3;
export const GENDER_STR_ENUMS = {
  [GENDER_MALE]: 'Male',
  [GENDER_FEMALE]: 'Female',
  [GENDER_OTHERS]: 'Other'
}

// TYPE OF CAMERA
export const CAMERA_NIKON = 1;
export const CAMERA_TOPCON = 2;
export const CAMERA_DRS = 3;
export const CAMERA_OTHERS = 4;
export const CAMERA_TELEEYE_SCAN = 5;

export const CAMERA_STR = {
  [CAMERA_NIKON]: "Nikon",
  [CAMERA_TOPCON]: "Topcon",
  [CAMERA_DRS]: "DRS",
  [CAMERA_OTHERS]: "Other",
  [CAMERA_TELEEYE_SCAN]: 'TeleEye Scan'
};

export const RECOMMENDED_NEXT_EXAM_ENUMS = {
  UNKNOWN: 1,
  AE: 2,
  TWO_WEEKS: 3,
  ONE_MONTH: 8,
  THREE_MONTHS: 5,
  SIX_MONTHS: 6,
  ONE_YEAR_NORMAL: 7,
  ONE_YEAR_NON_REFERABLE: 9,
};

export const RECOMMENDED_NEXT_EXAM_STR_ENUMS = {
  [RECOMMENDED_NEXT_EXAM_ENUMS.UNKNOWN]: "Unknown",
  [RECOMMENDED_NEXT_EXAM_ENUMS.AE]: "Accident and Emergency",
  [RECOMMENDED_NEXT_EXAM_ENUMS.TWO_WEEKS]: "2 weeks",
  [RECOMMENDED_NEXT_EXAM_ENUMS.ONE_MONTH]: "1 month",
  [RECOMMENDED_NEXT_EXAM_ENUMS.THREE_MONTHS]: "3 months",
  [RECOMMENDED_NEXT_EXAM_ENUMS.SIX_MONTHS]: "6 months",
  [RECOMMENDED_NEXT_EXAM_ENUMS.ONE_YEAR_NORMAL]: "Normal screening results - continue annual screening",
  [RECOMMENDED_NEXT_EXAM_ENUMS.ONE_YEAR_NON_REFERABLE]: "No referable conditions in screening results - Continue annual screening",
};

// COUNTRIES
export const AUSTRALIA = 'AU';
export const NEW_ZEALAND = 'NZ';
export const GERMANY = 'DE';
export const SINGAPORE = 'SG';
export const MALAYSIA = 'MY';
export const IRELAND = 'IE';
export const UNITED_KINGDOM = 'GB';
export const INDIA = 'IN';

// COUNTRIES STRING
export const COUNTRY_CODE_STR_ENUMS = {
  [AUSTRALIA]: "Australia",
  [NEW_ZEALAND]: "New Zealand",
  [SINGAPORE]: "Singapore",
  [MALAYSIA]: "Malaysia",
  [GERMANY]: "Germany",
  [IRELAND]: "Ireland",
  [UNITED_KINGDOM]: "United Kingdom",
  [INDIA]: "India",
};

export const COUNTRY_CODE_FLAG_ENUMS = {
  [AUSTRALIA]: "🇦🇺",
  [NEW_ZEALAND]: "🇳🇿",
  [SINGAPORE]: "🇸🇬",
  [MALAYSIA]: "🇲🇾",
  [GERMANY]: "🇩🇪",
  [IRELAND]: "🇮🇪",
  [UNITED_KINGDOM]: "🇬🇧",
  [INDIA]: "🇮🇳",
};

// PAGINATION
export const DEFAULT_PAGE_SIZE = 7;
// ----------------- STRING ---------------------
// Names
export const DR_STR = "Diabetic\n Retinopathy";
export const DR_FULL_STR = "DR Severity";
export const GLAUCOMA_STR = "Glaucoma";
export const GLAUCOMA_CDR_STR = "Cup to Disc Ratio";
export const AMD_STR = "Age-Related Macular Degeneration";
// export const AMDDR_STR = "AMD/DR Bias";
export const AMD_FINAL_STR = "AMD / DR Probability";
export const GC_LITE_STR = "Glaucoma";
export const DISC_ASYMMETRY_STR = "Disc Asymmetry";
export const DME_STR = "Diabetic Macular Edema";
export const MEDIA_OPACITY_STR = "Media Opacity";

// Services
export const SERVICE_STR_ENUM = {
  [DR_LITE]: "RetinoScan - Diabetic Retinopathy",
  [DR_FULL]: "RetinoScan Advanced - Diabetic Retinopathy",
  [GLAUCOMA_CDR]: "RetinoScan Advanced - Glaucoma CDR",
  [AUDIT_SVC]: "Eye Exam Review",
  [STDR_RISK]: "STDR Risk Prediction",
  [DR_RISK]: "DR Risk Prediction",
  [AMD_LITE]: "RetinoScan - AMD",
  [AMD_FINAL]: "AMD / DR Diseases Probability",
  [GC_LITE]: "Glaucoma Lite",
  [STDR_RISK]: "STDR Risk Prediction",
  [DR_RISK]: "DR Risk Prediction",
};

//
export const SERVICE_AUDIT_FIELD_NAMES = {
  [DR_LITE]: "dr_lite",
  [GC_LITE]: "glaucoma",
  [AMD_LITE]: "amd",
  [GLAUCOMA_CDR]: "cdr",
  [DR_FULL]: 'dr_level',
  [DME]: 'dme_level',
  [MEDIA_OPACITY]: 'media_opacity',
}

// Risk Service String to Risk Service Backend Number
export const RISK_STR_TO_SERVICES = {
  [SERVICE_STR_ENUM[STDR_RISK]]: 1,
  [SERVICE_STR_ENUM[DR_RISK]]: 2,
}

// Services with Icons
export const SERVICES_WITH_ICON = {
  [DR_LITE]: <div>DR<img style={{ margin: "0 0 -3px 10px" }} src={LITE} alt="LITE" /></div>,
  [AMD_LITE]: <div>AMD<img style={{ margin: "0 0 -3px 8px" }} src={LITE} alt="LITE" /></div>,
  // [AMDDR]: <div>AMD DR<img style={{ margin: "0 0 -3px 8px" }} src={LITE} alt="LITE" /></div>,
  [GC_LITE]: <div>GC<img style={{ margin: "0 0 -3px 8px" }} src={LITE} alt="LITE" /></div>,
  [DR_FULL]: <div>DR<img style={{ margin: "0 0 -3px 10px" }} src={FULL} alt="LITE" /></div>,
  [GLAUCOMA_CDR]: "C/D Ratio",
  [DR_RISK]: <img style={{ margin: "0 0 0 8px", height: "0.6cm" }} src={TELEMEDC_LOGO} alt="TELEMEDC LOGO" />,
  [STDR_RISK]: <img style={{ margin: "1px 0 0 8px", height: "0.6cm" }} src={RETINA_RISK_LOGO} alt="RETINA RISK LOGO" />,
  [AUDIT_SVC]: "Auditing",
};

// Services Description
export const SERVICES_DESCR = {
  [DR_LITE]: {
    title: "Simple Diabetic Retinopathy diagnosis tool",
    content:
      "DR Lite provides DR Levels of Normal (negative) and Abnormal (positive) based on your retina image.",
  },
  [DR_FULL]: {
    title: "Comprehensive Diabetic Retinopathy diagnosis tool",
    content: `DR Full is the complete version of DR Lite. It provides DR Levels of Normal, Mild NPDR, Moderate NPDR, Severe NPDR or worse,
        and also provides auditing service by Reviewers.`,
  },
  [GLAUCOMA_CDR]: {
    title: "Cup to Disc Ratio and Disc Asymmetry",
    content:
      "C/D Ratio provides Cup to Disc Ratio values and Disc Asymmetry value which might indicate the presence of Glaucoma disease.",
  },
  [AMDDR]: {
    title: "Aged Macular Degeneration or Diabetic Retinopathy",
    content:
      "Determines the disease of AMD or DR based on your AMD result.",
  },
  [AUDIT_SVC]: {
    title: "Eye Exam Review Service",
    content:
      "Eye Exam Review is performed by an eye specialist. Only abnormal cases are reviewed",
  },
  [STDR_RISK]: {
    title: "Sight Threatening Diabetic Retinopathy Risk Prediction Service",
    content: "STDR Risk Prediction Service is a service powered by Retina Risk that indicates a patient's chances of \
      developing Sight Threatening Diabetic Retinopathy within the next 12 months along with the recommended screening interval for that \
      patient."
  },
  [DR_RISK]: {
    title: "Diabetic Retinopathy Risk Prediction Service",
    content: "DR Risk Prediction Service is a service powered by TeleMedC that indicates a patient's chances of \
      developing Diabetic Retinopathy within the next few years."
  },
  [AMD_LITE]: {
    title: "Aged Macular Degeneration",
    content:
      "AMD Lite detects AMD (positive) or Normal (negative) based on your retina image.",
  },
  // [AMDDR]: {
  //   title: "Aged Macular Degeneration or Diabetic Retinopathy",
  //   content:
  //     "Determines the disease of AMD or DR based on your AMD result.",
  // },
  [GC_LITE]: {
    title: "Simple Glaucoma presence tool",
    content:
      "GC Lite indicate the presence of Glaucoma disease based on your retina image."
  },
};

// Statuses Description
export const STATUSES_DESCR = {
  [SERVICE_NOT_PURCHASED]: "Service hasn't been selected",
  [PENDING_ACTION]: "Pending for image upload",
  [PENDING_REVIEW]: "Pending for eye professional review",
  [AWAITING_RESULT]: "Waiting for results from services",
  [REPORT_READY]: "Report ready to download",
};

// ETHNICITY
export const ETHNIC_CODE_ENUMS = {
  ETHNIC_CHINESE: 1,
  ETHNIC_INDIAN: 2,
  ETHNIC_AB_AND_TSI: 3,
  // ETHNIC_OTHER: 4,
  ETHNIC_MALAY: 5,
  ETHNIC_CAUCASIAN: 6,
  ETHNIC_ABORIGINAL: 7, // migrated from 1
  ETHNIC_TORRES_STRAIT_ISLANDER: 8, // migrated from 2
  ETHNIC_OTHER: 98,
  ETHNIC_UNKNOWN: 99,
}

export const ETHNIC_CODE_ENUMS_STR = Object.fromEntries(Object.entries(ETHNIC_CODE_ENUMS).map(entry => entry.reverse()))

export const ETHNICS_STR = {
  AU: {
    ETHNIC_ABORIGINAL: "Aboriginal",
    ETHNIC_TORRES_STRAIT_ISLANDER: "Torres Strait Islander",
    ETHNIC_AB_AND_TSI: "Aboriginal and Torres Strait Islander",
    ETHNIC_OTHER: "Other",
  },
  SG: {
    ETHNIC_CHINESE: "Chinese",
    ETHNIC_INDIAN: "Indian",
    ETHNIC_MALAY: "Malay",
    ETHNIC_CAUCASIAN: "Caucasian",
    ETHNIC_OTHER: "Other",
  },
  DE: {
    ETHNIC_CAUCASIAN: "Caucasian",
    ETHNIC_CHINESE: "Chinese",
    ETHNIC_INDIAN: "Indian",
    ETHNIC_MALAY: "Malay",
    ETHNIC_OTHER: "Other",
  },
  IRELAND: {
    ETHNIC_CAUCASIAN: "Caucasian",
    ETHNIC_CHINESE: "Chinese",
    ETHNIC_INDIAN: "Indian",
    ETHNIC_MALAY: "Malay",
    ETHNIC_OTHER: "Other",
  },
  UNITED_KINGDOM: {
    ETHNIC_CAUCASIAN: "Caucasian",
    ETHNIC_CHINESE: "Chinese",
    ETHNIC_INDIAN: "Indian",
    ETHNIC_MALAY: "Malay",
    ETHNIC_OTHER: "Other",
  },
  INDIA: {
    ETHNIC_INDIAN: "Indian",
    ETHNIC_OTHER: "Other",
  }
};

// Product codes for the services
export const DR_LITE_PRODUCT_CODE = 1;
export const DR_FULL_PRODUCT_CODE = 2;
export const GLAUCOMA_CDR_PRODUCT_CODE = 3;
export const AUDIT_SERVICE_PRODUCT_CODE = 4;
export const STDR_SERVICE_PRODUCT_CODE = 5;
export const DR_SERVICE_PRODUCT_CODE = 6;
export const AMD_PRODUCT_CODE = 7;
// export const AMDDR_PRODUCT_CODE = 8;
export const GC_LITE_PRODUCT_CODE = 9;

// ---------------------- PAYMENT CODES ----------------------

export const PRODUCT_CODE_TO_SERVICE_NUM = {
  [AUDIT_SERVICE_PRODUCT_CODE]: AUDIT_SVC,
  [DR_FULL_PRODUCT_CODE]: DR_FULL,
  [GLAUCOMA_CDR_PRODUCT_CODE]: GLAUCOMA_CDR,
  [DR_LITE_PRODUCT_CODE]: DR_LITE,
  [DR_SERVICE_PRODUCT_CODE]: DR_RISK,
  [STDR_SERVICE_PRODUCT_CODE]: STDR_RISK,
  [AMD_PRODUCT_CODE]: AMD_LITE,
  // [AMDDR_PRODUCT_CODE]: AMDDR,
  [GC_LITE_PRODUCT_CODE]: GC_LITE,
};

// ---------------------- PAYMENT CURRENCY --------------------
export const EUR = 1;
export const GBP = 2;

export const CURRENCIES_STR = {
  [EUR]: "EUR",
  [GBP]: "GBP",
};

// ---------------------- OPEN MODEL --------------------------

export const OPEN_MODEL_GROUP = "Open Model"


// ---------------------- AUDIT ACCESS TYPES --------------------------

export const POOLED = 0
export const ASSIGNED = 1

// ---------------------- AUDIT ACCESS TYPES --------------------------

export const AUDIT_PENDING = 1
export const AUDIT_ASSIGNED = 2
export const AUDIT_COMPLETED = 3

// ---------------------- AUDIT STATUS TYPES --------------------------
export const AUDIT_ACCESS_AVAILABLE = 0
export const AUDIT_ACCESS_UNAVAILABLE = 1

// ---------------------- IMAGE MODALITY --------------------------
export const COLOUR_FUNDUS_PHOTOGRAPH = 1
export const RED_REFLEX_PHOTOGRAPH = 2

// ---------------------- Error codes from services --------------------------
export const DISC_OR_CUP_UNDETECTABLE = 601

// error codes to description string
export const SERVICES_ERR_STR = {
  [DISC_OR_CUP_UNDETECTABLE]: "Image(s) cannot be graded for glaucoma: disc or cup of images is undetectable."
}

export const EMAIL_TYPE_ENUM = {
  REPORT_LINK_EMAIL: 1,
  REPORT_PDF_EMAIL: 2,
  VERIFICATION_EMAIL: 3
}

export const OTP_PURPOSE_ENUM = {
  VERIFY_ACCOUNT: 1,
  RESET_PASSWORD: 2
}

