import {
    SUBSCRIPTION_INCLUSIONS_AU,
    SUBSCRIPTION_INCLUSIONS_DE, SUBSCRIPTION_INCLUSIONS_GB,
    SUBSCRIPTION_INCLUSIONS_IE, SUBSCRIPTION_INCLUSIONS_IN,
    SUBSCRIPTION_INCLUSIONS_SG
} from "../constants/subscriptions";

import {
    AUSTRALIA,
    ETHNICS_STR,
    GERMANY, INDIA,
    IRELAND,
    SINGAPORE, UNITED_KINGDOM
} from "../constants/constants";
import { AVAILABLE_COUNTRIES_BY_DEPLOY_REGION, SIGN_UP_FORM_FIELD_STR_ENUMS } from "../constants/regionSpecificConfigs";
import { DIAL_CODE_BY_COUNTRY } from "../constants/regionSpecificConfigs";
import { PHONE_REGEX_BY_COUNTRY } from "../constants/validations";

export const getDeployRegion = () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'ap-southeast-2': // sydney, australia (2)
            return AUSTRALIA;
        case 'ap-southeast-1': //singapore (1)
            return SINGAPORE;
        case 'eu-central-1': // frankfurt, germany
            return GERMANY;
        case 'eu-west-1': // Ireland
            return IRELAND;
        case 'eu-west-2': // GB Mainland
            return UNITED_KINGDOM;
        case 'ap-south-1': // Mumbai, India:
            return INDIA
        default:
            return console.error(`Deploy region not found ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const getRegionSpecificSubscriptionInclusions = () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'ap-southeast-2': // sydney, australia (2)
            return SUBSCRIPTION_INCLUSIONS_AU;
        case 'ap-southeast-1': //singapore (1)
            return SUBSCRIPTION_INCLUSIONS_SG;
        case 'eu-central-1': // frankfurt, germany
            return SUBSCRIPTION_INCLUSIONS_DE;
        case 'eu-west-1': // Ireland
            return SUBSCRIPTION_INCLUSIONS_IE;
        case 'eu-west-2': // GB Mainland
            return SUBSCRIPTION_INCLUSIONS_GB;
        case 'ap-south-1': // Mumbai, India:
            return SUBSCRIPTION_INCLUSIONS_IN;
        default:
            return console.error(`No subscription inclusions found for the region ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const checkRegionEnabledTeleconsult = () => {
    switch (process.env.REACT_APP_AWS_REGION) {
        // case 'ap-southeast-2': // Sydney, australia (2)
        //     return ['test', 'development', 'staging', 'production'].includes(process.env.REACT_APP_STAGE)
        // case 'ap-southeast-1': //Singapore
        //     return ['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)
        default:
            return false
    }
}

export const getRegionSpecificEthnicity = () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'ap-southeast-2': // sydney, australia (2)
            return ETHNICS_STR.AU;
        case 'ap-southeast-1': //singapore (1)
            return ETHNICS_STR.SG;
        case 'eu-central-1': // frankfurt, germany
            return ETHNICS_STR.DE;
        case 'eu-west-1': // ireland
            return ETHNICS_STR.IRELAND;
        case 'eu-west-2': // united kingdom
            return ETHNICS_STR.UNITED_KINGDOM;
        case 'ap-south-1': // mumbai, india
            return ETHNICS_STR.INDIA;
        default:
            return console.error(`No ethnicity configuration found for the region ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const getRegionSpecificSignUpFormFieldConfigs= () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'eu-central-1': // frankfurt, germany
            return SIGN_UP_FORM_FIELD_STR_ENUMS[GERMANY];
        case 'ap-southeast-2': // sydney, australia (2)
            return SIGN_UP_FORM_FIELD_STR_ENUMS[AUSTRALIA];
        case 'ap-southeast-1': //singapore (1)
            return SIGN_UP_FORM_FIELD_STR_ENUMS[SINGAPORE];
        case 'eu-west-1': // Ireland
            return SIGN_UP_FORM_FIELD_STR_ENUMS[IRELAND];
        case 'eu-west-2': // GB Mainland
            return SIGN_UP_FORM_FIELD_STR_ENUMS[UNITED_KINGDOM];
        case 'ap-south-1': // Mumbai, India:
            return SIGN_UP_FORM_FIELD_STR_ENUMS[INDIA];
        default:
            return console.error(`No sign up form field configuration found for the region ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const getSignUpFormFieldConfigsByCountry= (country="") => {
    const countryCode = country.toUpperCase();
    return SIGN_UP_FORM_FIELD_STR_ENUMS[countryCode];
}


/**
 * Get the service available countries for the current instance
 * @returns {[string]} - array of country codes
 */
export const getAvailableCountries = () => {
    return AVAILABLE_COUNTRIES_BY_DEPLOY_REGION[process.env.REACT_APP_AWS_REGION] || [];
}

export const getPhoneRegexByCountry = (countryCode='') => {
    return PHONE_REGEX_BY_COUNTRY[countryCode.toUpperCase()] || ''
}

export const getDialCodeByCountry = (countryCode='') => {
    return DIAL_CODE_BY_COUNTRY[countryCode.toUpperCase()] || '';
}

export const getCountryByDialCode = (dialCode) => {
    const country = Object.keys(DIAL_CODE_BY_COUNTRY).find(key => DIAL_CODE_BY_COUNTRY[key] === dialCode);
    return country || '';
}