import {
	ASSIGN_LATERALITY,
	CLEAR_EXAM_DETAILS,
	CREATE_EXAM,
	FORM_FIELDS_ADDED,
	GET_EXAM_DETAILS,
	GET_EXAM_DETAILS_NO_REFRESH_IMAGES,
	GET_EXAMS_BY_OPERATOR,
	GET_EXISTING_EXAMS_BY_OPERATOR,
	GET_IMAGE_DETAILS,
	GET_NUMBER_OF_EXAM_PAGES,
	GET_REPORT_SENT_STATS,
	GET_URGENT_EXAMS,
	GRADING_SUBMITTED,
	IMAGE_DELETED,
	IMAGE_GRADED,
	IMAGE_UPLOADED,
	IMAGES_SUBMITTED,
	LIST_AUDITORS,
	REFRESH_EXAM_DETAILS,
	SET_EXAM_PAGINATION_INFO,
	UPDATE_ORDER_DETAILS
} from "../actions/types.js";
import { calculateStatusByResult, calcWorseCase, separateLeftRightImages } from "../utils/calculations.js";
import { LR_RESULT_ENUMS } from "../constants/results.js";
import { AMDDR, EXAM_ORDERED, EXAM_PENDING, EXAM_SUBMITTED, LR, PENDING_ACTION } from "../constants/constants.js";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
	exam_id: '',
	exam_details: {},
	exams: {},
	pages: {},
};

const examReducer = createReducer(initialState, (builder) => {
	let exam_details = null;

	// is currently creating eye exam?
	builder
		.addCase(CREATE_EXAM, (state, action) => {
			state.exam_status = true;
			state.exam_id = action.payload;
		})
		.addCase(GET_EXAMS_BY_OPERATOR, (state, action) => {
			let _exams = {};
			for (let exam of action.payload.results) {
				_exams = {
					..._exams,
					[exam.id]: exam,
				};
			}
			let statePages = action.request_type === 'filter_sort' ? null : state.pages;

			(state.exams = {
				...state.exams,
				..._exams,
			}),
				(state.pages = {
					...statePages,
					currentPage: action.payload.currentPage,
					numberOfPages: Math.ceil(action.payload.count / action.payload.countItemsOnPage),
					[action.payload.currentPage]: {
						ids: action.payload.results.map((item) => item.id),
						fetched: true,
					},
				}),
				(state.status_filter = action.statuses);
			state.process_date_order = action.order;
		})
		.addCase(GET_EXISTING_EXAMS_BY_OPERATOR, (state, action) => {
			state.pages = {
				...state.pages,
				currentPage: action.payload,
			};
		})
		.addCase(GET_NUMBER_OF_EXAM_PAGES, (state, action) => {
			state.pages = {
				...state.pages,
				numberOfPages: action.payload.number_of_pages,
			};
		})
		.addCase(GET_EXAM_DETAILS, (state, action) => {
			const examDetailsFilterAMDDR = action.payload.service_results.filter(function (obj) {
				return obj.service_type !== AMDDR;
			});

			delete action.payload.service_results;

			state.exam_id = action.payload.id;
			state.exam_details = Object.assign(action.payload, { service_results: examDetailsFilterAMDDR });
		})
		.addCase(GET_EXAM_DETAILS_NO_REFRESH_IMAGES, (state, action) => {
			let eye_screening_image = [...state.exam_details.eye_screening_image];

			state.exam_id = action.payload.id;
			state.exam_details = { ...action.payload, eye_screening_image };
		})
		.addCase(CLEAR_EXAM_DETAILS, (state, action) => {
			state.exam_details = {};
			state.examToRefresh = '';
		})
		.addCase(GET_IMAGE_DETAILS, (state, action) => {
			exam_details = { ...state.exam_details };
			if (exam_details.eye_screening_image && Array.isArray(exam_details.eye_screening_image))
				exam_details.eye_screening_image.forEach((image) => {
					if (image.id === action.payload.id) {
						image['image'] = action.payload.image;
						image['image_grading_services'] = action.payload.image_grading_services;
					}
				});
			state.exam_details = exam_details;
		})
		.addCase(GET_URGENT_EXAMS, (state, action) => {
			const urgent_exams = action.payload || [];
			urgent_exams.sort((a, b) => {
				return new Date(b.audited_at) - new Date(a.audited_at);
			});
			state.urgent_exams = urgent_exams;
		})
		.addCase(IMAGE_GRADED, (state, action) => {
			// RESULTS ALLOCATION
			exam_details = { ...state.exam_details };
			// make sure websocket update the right exam without affecting other exams
			if (exam_details.id !== action.payload.eye_screening_id) {
				return { ...state };
			}
			if (!Array.isArray(exam_details.eye_screening_image)) {
				exam_details.eye_screening_image = [];
			}

			// set default overall status to Pending Action
			exam_details['overall_status'] = action.payload.overall_status || PENDING_ACTION;

			if (exam_details?.eye_screening_image) {
				exam_details.eye_screening_image.forEach((image) => {
					let serviceExists = false;

					if (image.id === action.payload.eye_screening_image_id) {
						// when image_grading_services is yet to be declared
						if (!image.image_grading_services) {
							image.image_grading_services = [];
						} else {
							// if service exists, replace it
							image.image_grading_services.forEach((service, index) => {
								if (service.service_type === action.payload.service_type) {
									serviceExists = true;
									image.image_grading_services[index].result = action.payload.result;
									image.image_grading_services[index].status = calculateStatusByResult(
										action.payload.result,
										action.payload.service_type
									);
								}
							});
						}
						if (!serviceExists) {
							image.image_grading_services.push({
								id: action.payload.id,
								result: action.payload.result,
								service_type: action.payload.service_type,
								status: calculateStatusByResult(action.payload.result, action.payload.service_type),
							});
						}
					}
				});
			}

			if (
				exam_details &&
				exam_details.process &&
				exam_details.service_results &&
				exam_details.process.status !== EXAM_PENDING &&
				exam_details.process.status !== EXAM_ORDERED
			) {
				// push to summary results

				//find index of service_results to edit or push
				const serviceIndex = exam_details.service_results.findIndex(
					(service) => service.service_type === action.payload.service_type
				);

				//separate left right images
				const leftRightImages = separateLeftRightImages(exam_details.eye_screening_image);

				// calc results left & right for this service
				const leftResult = calcWorseCase(leftRightImages[LR_RESULT_ENUMS.LEFT], action.payload.service_type);
				const rightResult = calcWorseCase(leftRightImages[LR_RESULT_ENUMS.RIGHT], action.payload.service_type);

				if (
					exam_details &&
					exam_details.service_results &&
					exam_details.service_results.length > 0 &&
					exam_details.service_results[serviceIndex]
				) {
					// LR_RESULT_ENUMS.LEFT
					if (leftResult) {
						// determine index
						const leftIndex = exam_details.service_results[serviceIndex].laterality.findIndex(
							(result) => result.laterality === LR_RESULT_ENUMS.LEFT
						);
						const resultToAddLeft = {
							laterality: LR_RESULT_ENUMS.LEFT,
							result: leftResult.result,
							status: calculateStatusByResult(leftResult.result, action.payload.service_type),
						};
						// either add or replace
						if (leftIndex === -1) {
							exam_details.service_results[serviceIndex].laterality.push(resultToAddLeft);
						} else {
							exam_details.service_results[serviceIndex].laterality.splice(leftIndex, 1, resultToAddLeft);
						}
					}

					// LR_RESULT_ENUMS.RIGHT
					if (rightResult) {
						// determine index
						const rightIndex = exam_details.service_results[serviceIndex].laterality.findIndex(
							(result) => result.laterality === LR_RESULT_ENUMS.RIGHT
						);
						const resultToAddRight = {
							laterality: LR_RESULT_ENUMS.RIGHT,
							result: rightResult.result,
							status: calculateStatusByResult(rightResult.result, action.payload.service_type),
						};
						// either add or replace
						if (rightIndex === -1) {
							exam_details.service_results[serviceIndex].laterality.push(resultToAddRight);
						} else {
							exam_details.service_results[serviceIndex].laterality.splice(
								rightIndex,
								1,
								resultToAddRight
							);
						}
					}
				}
			}
			state.exam_details = exam_details;
		})
		.addCase(IMAGE_UPLOADED, (state, action) => {
			exam_details = { ...state.exam_details };
			if (!exam_details.eye_screening_image || !Array.isArray(exam_details.eye_screening_image)) {
				exam_details.eye_screening_image = [];
			}
			exam_details.eye_screening_image.push(action.payload);
			state.exam_details = exam_details;
		})
		.addCase(IMAGES_SUBMITTED, (state, action) => {
			exam_details = { ...state.exam_details };
			exam_details.eye_screening_image = action.payload.images;

			state.exam_details = exam_details;
		})
		.addCase(ASSIGN_LATERALITY, (state, action) => {
			exam_details = {
				...state.exam_details,
			};
			let eye_screening_image = state.exam_details.eye_screening_image;
			for (let i in eye_screening_image) {
				if (eye_screening_image[i].id === action.payload.id) {
					eye_screening_image[i] = action.payload;
				}
			}

			state.exam_details = {
				...exam_details,
				eye_screening_image,
			};
		})
		.addCase(IMAGE_DELETED, (state, action) => {
			exam_details = { ...state.exam_details };
			exam_details.eye_screening_image = exam_details.eye_screening_image.filter(
				(image) => image.id !== action.payload.id
			);
			state.exam_details = exam_details;
		})
		.addCase(GRADING_SUBMITTED, (state, action) => {
			exam_details = { ...state.exam_details };
			exam_details.process.status = EXAM_SUBMITTED;
			if (!exam_details.service_results.length) exam_details.service_results = [];

			// CHECK IF LR_RESULT_ENUMS.LEFT OR LR_RESULT_ENUMS.RIGHT IMAGES EXIST (SOMEONE MIGHT ONLY UPLOAD ONE EYE IMAGE)
			const left = exam_details.eye_screening_image.some((image) => {
				return image.image_grading_services.some(
					(service) => service.service_type === LR && service.result === LR_RESULT_ENUMS.LEFT
				);
			});
			const right = exam_details.eye_screening_image.some((image) => {
				return image.image_grading_services.some(
					(service) => service.service_type === LR && service.result === LR_RESULT_ENUMS.RIGHT
				);
			});
			let leftRight = [];
			if (left) {
				leftRight.push({ laterality: LR_RESULT_ENUMS.LEFT, status: EXAM_PENDING });
			}
			if (right) {
				leftRight.push({ laterality: LR_RESULT_ENUMS.RIGHT, status: EXAM_PENDING });
			}
			action.payload.services.forEach((service) => {
				exam_details.service_results.push({
					service_type: service,
					laterality: [...leftRight],
				});
			});

			state.exam_details = exam_details;
		})
		.addCase(FORM_FIELDS_ADDED, (state, action) => {
			exam_details = { ...state.exam_details, ...action.payload };
		})
		.addCase(LIST_AUDITORS, (state, action) => {
			state.auditors = action.payload.data;
		})
		.addCase(UPDATE_ORDER_DETAILS, (state, action) => {
			state.exam_details = { ...state.exam_details, eye_screening_order: action.payload };
		})
		.addCase(SET_EXAM_PAGINATION_INFO, (state, action) => {
			state.examPaginationInfo = action.payload;
		})
		.addCase(REFRESH_EXAM_DETAILS, (state, action) => {
			const { eye_screening_id, services_all_completed, services } = action.payload;
			state.servicesLoadingStatus = { ...state.servicesLoadingStatus, [eye_screening_id]: { ...services } };
			if (services_all_completed) {
				state['examToRefresh'] = eye_screening_id;
			}
		})
		.addCase(GET_REPORT_SENT_STATS, (state, action) => {
			state.exam_details = { ...state.exam_details, report_sent_stats: action.payload };
		})
		.addDefaultCase((state, action) => {
			// console.log('default case', action);
		});
});

export default examReducer;
