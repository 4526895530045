import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import Loading from '../layout/organisms/Loading/Loading';
import { connect } from 'react-redux';
const PrivateRoute = ({ component: Component, auth,
    ...rest }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const nextUrl = queryParams.toString()
    const loginUrl = "/login/" + (nextUrl ? `?${nextUrl}`: "")
    return (
        <Route
            {...rest}
            render={props => {
                if (auth.isLoading) {
                    return <Loading isFullScreen/>
                } else if (!auth.isAuthenticated || !auth.user) {
                    return <Redirect to={loginUrl} />
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
