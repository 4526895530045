import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Loading from '../organisms/Loading/Loading';

const StyledButton = styled(Button, {
	shouldForwardProp: (prop) => !['color', 'accent', 'noMargin', 'loading'].includes(prop),
})(({ theme,fullWidth, loading, noMargin }) => ({
	margin: noMargin ? 0 : theme.spacing(1),
	padding: loading ? 0 : theme.spacing(2),
	boxShadow: theme.shadows[0],
	minWidth: fullWidth? '100%': '15ch',
	borderRadius: theme.shape.borderRadiusSmall,
	zIndex: '200',
	minHeight: '56.5px',
	textTransform: 'capitalize',
	fontWeight: 700,
	backgroundColor: theme.palette.background.dark,
	'&:hover': {
		backgroundColor: theme.palette.button.light,
	},
}));

const LargeButtonSecondary = (props) => {
	return <StyledButton {...props}>{props.loading ? <Loading size="xs" /> : props.children}</StyledButton>;
};

export default LargeButtonSecondary;
