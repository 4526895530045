import { createTheme, } from '@mui/material';
// import { styled } from '@mui/styles';

const theme = createTheme({

  palette: {
    /* --------------------------- main colors -------------------------------- */
    primary: { main: '#3956CC', light: '#3877F3', dark: '#4139B8', disabled: '#D8D9E0' },
    secondary: { main: '#6797F6', light: '#96B7F9', dark: '#6797F6', contrastText: '#43425D' }, // to be edited
    translucent: { normal: 'rgba(224, 227, 233, 0.7)', darker: '#D5DBE7', font: '#9E9DB4' },

    /* --------------------- four dashboard colors ---------------------------- */
    error: { main: '#FF8373', light: '#FFDCD8', dark: '#a30028' }, // red
    warning: { main: '#FFC114', light: '#FFF0C5', dark: '#ff6f00' }, // yellow
    info: { main: '#55D8FE', light: '#CEF4FF', dark: '#1063AB' }, // blue
    success: { main: '#86C548', light: '#DAF2C2', dark: '#356b00' }, // green

    /* -------------------------- other colors -------------------------------- */
    button: { light: '#D8E3FD', hover: '#9e9e9e50'},
    background: { default: '#F6F8FF',grey: '#F9F9FB',dark: '#ECF1FE' },
    text: { primary: '#4D4F5C', secondary: '#9E9DB4', disabled: '#706F8E', hint: '#A3A6B4' },
    divider: 'rgba(216,202,225,0.5)',
    border: { default: '#EBEBEB' }
  },
  typography: {
    fontFamily: '"Cabin", "Helvetica", "Arial", "sans-serif"',
    fontFamilyTitle: '"Poppins", "Helvetica", "Arial", "sans-serif"',
    h1: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '30px',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '16px',
      fontWeight: '700',
    },
    h5: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '14px',
      fontWeight: '600',
      color: '#888',
    },
    //Dashboard Subtitle
    h6: {
      fontFamily: '"Cabin", "Helvetica", "Arial", "sans-serif"',
      fontSize: '25px',
      fontWeight: '600',
      color: '#888',
    },
    subtitle1: { color: 'rgba(137, 136, 168, 0.83)' },
    button: { fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"' },
    caption: { color: 'rgba(137, 136, 168, 0.83)' },
    caption2: { color: 'rgba(137, 136, 168, 0.83)', fontSize: '14px' },
    links: {
      backgroundColor: "red",
      textDecoration: 'underline',
      cursor: 'pointer'
    },

  },
  shape: { borderRadius: 15, borderRadiusSmall: '10px', borderRadiusMedium: '15px' },
  outline: { border: '1px solid rgba(216,202,225,0.5)', thicker: '3px solid rgba(216,202,225,0.5)', card: '1px solid rgba(224, 227, 233, 0.7)' },
  MuiToggleButton: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        backgroundColor: "red"
      },
    },
  },
  //override Mui v5 components
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
})

theme.shadows[0] = '0 2px 6px rgba(0,0,0,0.04)';
theme.shadows[1] = '0px 0 15px rgba(0,0,0,0.1)';
theme.shadows[6] = '0 10px 13px rgba(59,21,110,0.13)';
theme.shadows[7] = '0 10px 13px rgba(59,21,110,0.13)';
theme.shadows[8] = '0px 0px 5px #e0e0e0'; //exam accordion
theme.shadows[9] = '0 20px 13px rgba(201, 201, 201, 1)'; //image card

// theme.overrides.MuiToggleButton = {
//   // Override the styling for selected toggle buttons
//   root: {
//     '&$selected': {
//       backgroundColor: theme.palette.primary.light,
//       color: 'white'
//     },
//     // override default hover color of selected button
//     '.MuiButtonBase-root&$selected:hover': {
//       backgroundColor: theme.palette.primary.light,
//     }
//   },
// };

// styled(ToggleButton, {
//   shouldForwardProp: (prop) => prop !== "selectedColor"
// })(({ selectedColor }) => ({
//   "&.Mui-selected, &.Mui-selected:hover": {
//     backgroundColor: theme.palette.primary.light,
//     color: 'white'
//   },
//   "&.Mui-selected:hover": {
//     backgroundColor: theme.palette.primary.light,
//   }
// }));

export default theme;