import { GLAUCOMA_CDR, LR, QA } from "../constants/constants";
import {
	CDR_ABNORMAL_THRESHOLD,
	GENERAL_RESULT_ENUMS,
	LR_RESULT_ENUMS, SERVICE_GRADING_STATUS
} from "../constants/results";

/**
 * categorize the images base on the laterality
 * @param {array} images images that need to be categorized according to their laterality
 * @returns {{ [LR_RESULT_ENUMS.LEFT]: [], [LR_RESULT_ENUMS.RIGHT]: [] }} an object contains categorized left and right images
 */
export const separateLeftRightImages = (images = []) => {
	return images.reduce(
		(separatedLeftRightImages, image) => {
			if (image.image_grading_services) {
				const LRService = image.image_grading_services.find((service) => service.service_type === LR);
				if (LRService) {
					if (LRService.result === LR_RESULT_ENUMS.LEFT) {
						separatedLeftRightImages[LR_RESULT_ENUMS.LEFT].push(image);
					} else if (LRService.result === LR_RESULT_ENUMS.RIGHT) {
						separatedLeftRightImages[LR_RESULT_ENUMS.RIGHT].push(image);
					}
				}
			}
			return separatedLeftRightImages;
		},
		{ [LR_RESULT_ENUMS.LEFT]: [], [LR_RESULT_ENUMS.RIGHT]: [] }
	);
};

export const calcWorseCaseHelper = (image, worseCase, serviceType) => {
	if (image.image_grading_services) {
		const service = image.image_grading_services.find((service) => service.service_type === serviceType);
		if (!service) return;
		if (worseCase === null) {
			worseCase = service;
		} else if (serviceType === GLAUCOMA_CDR) {
			if (worseCase.result == GENERAL_RESULT_ENUMS.UNABLE_TO_GRADE) {
				worseCase = service;
			} else if (service.result != GENERAL_RESULT_ENUMS.UNABLE_TO_GRADE && service.result > worseCase.result) {
				worseCase = service;
			}
		} else if (service.result > worseCase.result) {
			worseCase = service;
		}

		return worseCase;
	}
};
export const calcWorseCase = (images, serviceType) => {
	let worseCase = null;
	if (images) {
		images.forEach((image) => {
			worseCase = calcWorseCaseHelper(image, worseCase, serviceType);
		});
	}
	return worseCase;
};

/**
 * calculate the worst results of services for the given images
 * @param {array} images left or right images that need to calculate the worse case
 * @returns {array} an array contains the worst results of services
 */
export const calculateWorstCasesByImages = (images) => {
	return images.reduce((imagesGradingServices, image) => {
		if (imagesGradingServices.length === 0) {
			return image.image_grading_services;
		}
		return imagesGradingServices.map((imagesGradingService) =>
			calcWorseCaseHelper(image, imagesGradingService, imagesGradingService.service_type)
		);
	}, []);
};

// accepts left & right results array and returns separated
export const separateLeftRightResults = (results) => {
	let left,
		right = null;
	results.forEach((result) => {
		if (result.laterality === LR_RESULT_ENUMS.LEFT) left = result;
		else right = result;
	});

	return [left, right];
};

// accepts an image file and returns left or right based on its LR grading result
export const getLRService = (file) => {
	if (file.image_grading_services) {
		return file.image_grading_services.filter((service) => service.service_type === LR)[0];
	} else {
		return null;
	}
};

export const getQAService = (file) => {
	if (file.image_grading_services) {
		return file.image_grading_services.filter((service) => service.service_type === QA)[0];
	} else {
		return null;
	}
};

export const getAllServicesStatus = (allServiceResults) => {
	return allServiceResults.map((serviceResult) => ({
		serviceType: serviceResult.service_type,
		serviceCompleted: calculateServiceStatus(serviceResult.laterality),
	}));
};

export const calculateServiceStatus = (resultsByLaterality) => {
	return (
		Array.isArray(resultsByLaterality) &&
		!resultsByLaterality.find((lateralResult) => lateralResult.status < SERVICE_GRADING_STATUS.RESULT_COMPLETED)
	);
};

export const calculateStatusByResult = (result, serviceType) => {
	if (serviceType === GLAUCOMA_CDR) {
		return (result < 1 && result > 0) || result === GENERAL_RESULT_ENUMS.UNABLE_TO_GRADE ? SERVICE_GRADING_STATUS.RESULT_COMPLETED : SERVICE_GRADING_STATUS.RESULT_PENDING;
	} else return result > GENERAL_RESULT_ENUMS.NO_RESULT ? SERVICE_GRADING_STATUS.RESULT_COMPLETED : SERVICE_GRADING_STATUS.RESULT_PENDING;
};

export const calculateDiscAsymmetry = (leftEyeCDR, rightEyeCDR) => {
	if (leftEyeCDR && rightEyeCDR && leftEyeCDR > 0 && rightEyeCDR > 0 && leftEyeCDR < 1 && rightEyeCDR < 1) {
		return Math.abs(leftEyeCDR.toFixed(2) - rightEyeCDR.toFixed(2)).toFixed(2);
	}
};

export const calculateDiseaseLevelByCDR = (cdr) => {
	if ( cdr >= CDR_ABNORMAL_THRESHOLD && cdr < 1) {
		return GENERAL_RESULT_ENUMS.REFERABLE;
	}
	if (cdr < CDR_ABNORMAL_THRESHOLD && cdr > 0) {
		return GENERAL_RESULT_ENUMS.NON_REFERABLE;
	}
	return GENERAL_RESULT_ENUMS.UNABLE_TO_GRADE
};

// export const calcAMDDRFinalResultByServices = (drlite_result, amd_result, amddr_result) => {
//     let final_result = null;

//     if ((drlite_result == NORMAL) && (amd_result == NORMAL)){
//         final_result = NORMAL;
//     }else if ((drlite_result == SUSPECT) && (amd_result == NORMAL)){
//         final_result = AMD_DR_DISEASE; //Suspect DR
//     } else if ((drlite_result == NORMAL) && (amd_result == SUSPECT)){
//         final_result = AMD_DISEASE; //Suspect AMD
//     }else if ((drlite_result == SUSPECT) && (amd_result == SUSPECT)
//         && (amddr_result == AMD_DISEASE)){
//         final_result = AMD_HP; //Suspect AMD HP
//     }else if ((drlite_result == SUSPECT) && (amd_result == SUSPECT)
//           && (amddr_result == DR_DISEASE)){
//         final_result = AMD_DR_HP; //Suspect DR HP
//     } else if ((amd_result == GENERAL_RESULT_ENUMS.NO_RESULT) && (amddr_result == GENERAL_RESULT_ENUMS.NO_RESULT)){
//         final_result = GENERAL_RESULT_ENUMS.NO_RESULT;
//     } else {
//         final_result = GENERAL_RESULT_ENUMS.NO_RESULT;
//     }
//     return final_result
// }
