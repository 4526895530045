import axiosInstance from "../axios";
import { ROLES_STR, TELEEYE_MD_WEB } from "../constants/constants";
import {
	AUTH_ERROR,
	CHECK_OTP_USAGE,
	INVITATION_CHECK,
	INVITATION_RESPONSE,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	SET_SNACKBAR_ERROR,
	SET_SNACKBAR_INFO,
	SET_SNACKBAR_SUCCESS,
	SIGNUP_SUCCESS,
	TERMS_RECEIVED,
	UNAGREED_TERMS_RECEIVED,
	USER_LOADED,
	USER_LOADING
} from "./types";
import { VALIDATION_ERROR_CODES } from "../constants/validations";
import { checkCookie } from "../utils/helpers";

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch) => {
	dispatch({ type: USER_LOADING });
	try {
		let result = await axiosInstance.get("/users/accounts/login/status/");

		if (result.data.login_status) {
			result = await axiosInstance.get("/users/");

			dispatch({
				type: USER_LOADED,
				payload: result.data,
			});
		} else {
			dispatch({
				type: AUTH_ERROR,
			});
		}
	} catch {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

// LOGIN USER
export const login = (username, password) => async (dispatch) => {
	const loginData = JSON.stringify({ username, password });
	try {
		if (!checkCookie('csrftoken')) {
			console.log('get csrf token')
			await axiosInstance.get("/get_csrf/");
		}
		await axiosInstance.post("/login/", loginData);
		const result = await axiosInstance.get("/users/");

		dispatch({
			type: LOGIN_SUCCESS,
			payload: result.data,
		});
		dispatch({
			type: SET_SNACKBAR_SUCCESS,
			payload: "Successfully logged in!",
		});
		return result.data;
	} catch (error) {
		dispatch({
			type: LOGIN_FAIL,
		});
		const message = error.response?.data?.errors?.[0]?.message ?? "There was a problem logging in. Please try again later.";
		const code = error.response?.data?.errors?.[0]?.code ?? null;
		const status = error.response?.status;
		if (code === VALIDATION_ERROR_CODES.EMAIL_NOT_VERIFIED || code === VALIDATION_ERROR_CODES.PHONE_NUMBER_NOT_VERIFIED) {
			return Promise.reject({status, message, code});
		}
		dispatch({
			type: SET_SNACKBAR_ERROR,
			payload: message,
		});
		return Promise.reject({status, message, code});
	}
};

// LOGOUT USER
export const logOut = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post("/logout/")
			.then((result) => {
				dispatch({
					type: LOGOUT_SUCCESS,
				});
				resolve();
			})
			.catch((error) => {
				if (error.response?.status === 401) {
					dispatch({
						type: SET_SNACKBAR_ERROR,
						payload: "The session is already ended.",
					})
					window.location.replace('/login/')
				} else {
					dispatch({
						type: SET_SNACKBAR_ERROR,
						payload: "Sorry, we couldn't log you out at the moment. Please try again later.",
					});
				}
				reject(error);
			});
	});
};

// REGISTER USER
export const signUp = (user) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post("/users/accounts/signup/", {
				...user,
				signup_application: TELEEYE_MD_WEB,
			})
			.then((result) => {
				dispatch({
					type: SIGNUP_SUCCESS,
					payload: result.data,
				});
				dispatch({
					type: SET_SNACKBAR_SUCCESS,
					payload: "You have successfully signed up.",
				});
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
				dispatch({
					type: SET_SNACKBAR_ERROR,
					payload:
						error.response?.data?.errors?.[0]?.message ??
						"There was a problem signing up. Please try again later.",
				});
			});
	});
};

export const checkAccountExists = (emailOrPhoneNumber, silent=true) => (dispatch) => {
	return new Promise((resolve, reject) => {
		if (!emailOrPhoneNumber) return reject('No email or phone number provided');
		axiosInstance
			.get(`/users/accounts/check_exists/${emailOrPhoneNumber}/`)
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
				if (!silent) {
					dispatch({
						type: SET_SNACKBAR_ERROR,
						payload: "There was a problem checking the account. Please try again later.",
					});
				}
			});
	});
}

// ACTIVATE USER
export const activateUser = (
	uid,
	token,
	password1 = null,
	password2 = null,
	termsOfUseIds = null
) => (dispatch) => {
	return new Promise((resolve, reject) => {
		if (!password1)
			axiosInstance
				.get(`/users/accounts/activate/${uid}/${token}/`)
				.then((result) => {
					resolve(result.data);
				})
				.catch((error) => {
					reject(error);
				});
		else {
			axiosInstance
				.post(`/users/accounts/activate/set_password/${uid}/${token}/`, {
					password1,
					password2,
					terms_and_conditions_id: termsOfUseIds,
				})
				.then((result) => {
					resolve(result.data);
				})
				.catch((error) => {
					reject(error);
				});
		}
	});
};

// FORGOT PASSWORD
export const forgotPassword = (email) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post(`/users/accounts/password/reset/`, {
				email,
			})
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getPasswordResetLink = (username, otp, silent) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post(`users/accounts/password/reset/get_link`, {username, otp})
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				const message = error.response?.data?.errors?.[0]?.message || `Something wrong. Please try later.`;
				const code = error.response?.data?.errors?.[0]?.code || null;
				if (!(silent && code === VALIDATION_ERROR_CODES.INVALID_CREDENTIALS)) {
					dispatch({
						type: SET_SNACKBAR_ERROR,
						payload: message,
					});
				}
				reject({ message, code });
			});
	});
};

// FORGOT PASSWORD CONFIRM
export const forgotPasswordConfirm = (
	uid,
	token,
	password1 = null,
	password2 = null
) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post(`/users/accounts/password/reset/${uid}/${token}/`, {
				password1,
				password2,
			})
			.then((result) => {
				resolve(result.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

// CREATE USER
export const createUser = (user) => (dispatch) => {
	return new axiosInstance
			.post("/users/accounts/create/", { ...user, signup_application: TELEEYE_MD_WEB })
			.then((result) => {
				dispatch({
					type: SET_SNACKBAR_SUCCESS,
					payload: "Successfully created!",
				});
				return result.data;
			})
			.catch((error) => {
				dispatch({
					type: SET_SNACKBAR_ERROR,
					payload:
						error.response?.data?.errors?.[0]?.message ??
						`There was a problem creating a new ${ROLES_STR[user.role]
						}. Please try again.`,
				});
				throw error;
			});
};

// TERMS OF USE LIST
export const listTermsOfUse = () => async dispatch => {
	try {
		const result = await axiosInstance
			.get("/users/terms/list/active/")

		dispatch({
			type: TERMS_RECEIVED,
			payload: result.data,
		});

	} catch (error) {
		dispatch({
			type: SET_SNACKBAR_ERROR,
			payload:
				"There was a problem when retrieving Terms and Conditions data. Please refresh to try again.",
		});
	};
};

export const listUnagreedTermsOfUse = () => (dispatch) => {
	axiosInstance
		.get("/users/terms/latest_unagreed/")
		.then((result) => {
			dispatch({
				type: UNAGREED_TERMS_RECEIVED,
				payload: result.data,
			});
		})
		.catch((error) => {
			dispatch({
				type: SET_SNACKBAR_ERROR,
				payload:
					"There was a problem when retrieving Terms and Conditions data. Please refresh to try again.",
			});
		});
};

export const acceptTermsOfUse = (ids) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post("/users/terms/accept/", ids)
			.then((result) => {
				resolve(result.data)
				dispatch({
					type: SET_SNACKBAR_SUCCESS,
					payload: "Thank you for accepting the updated terms of use.",
				});
			})
			.catch((error) => {
				reject(error)
				dispatch({
					type: SET_SNACKBAR_ERROR,
					payload:
						"There was a problem when accepting Terms and Conditions. Please contact us if it persists`.",
				});
			});
	});
};

// INVITE USER VIA LINK
export const getInvitationLink = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.get("/users/referrals/generate-invitation/")
			.then((result) => {
				resolve(result.data);
				dispatch({
					type: SET_SNACKBAR_SUCCESS,
					payload: "Invitation Link Copied! Valid for 24 hours.",
				});
			})
			.catch((error) => {
				dispatch({
					type: SET_SNACKBAR_ERROR,
					payload:
						error.response?.data?.errors?.[0]?.message ??
						`There was a problem getting the invite link. Please try again.`,
				});
			});
	});
};

// INVITE USER
export const sendInvitation = (emails) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post("/users/referrals/email-invitation/", emails)
			.then((result) => {
				resolve(result.data);
				dispatch({
					type: SET_SNACKBAR_SUCCESS,
					payload: "Successfully Invited!",
				});
			})
			.catch((error) => {
				dispatch({
					type: SET_SNACKBAR_ERROR,
					payload:
						error.response?.data?.errors?.[0]?.message ??
						`There was a problem inviting ${emails}. Please try again.`,
				});
			});
	});
};

// Check if it's coming from invitation link
export const checkForInvitation = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.get("/users/referrals/check-for-invitation/")
			.then((result) => {
				resolve(result.data)
				dispatch({
					type: INVITATION_CHECK,
					payload: result.data,
				});
			})
			.catch((error) => {
				//reject(error)
				dispatch({
					type: SET_SNACKBAR_ERROR,
					payload:
						error.response?.data?.errors?.[0]?.message ??
						`There was a problem retrieving invitation referrals. Please try again`
				});

			});
	});
};

// takes body param 'type' with 'ACCEPT' and 'DECLINE' choices :both string
export const respondToInvitation = (invitationResponse, newGroup) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post("/users/referrals/respond-to-invitation/", invitationResponse)
			.then((result) => {
				resolve(result.data)
				dispatch({
					type: INVITATION_RESPONSE,
					payload: { response: invitationResponse, newGroup: newGroup }
				});
				if (invitationResponse.type === "ACCEPT") {
					dispatch({
						type: SET_SNACKBAR_SUCCESS,
						payload:
							`Accepted Invitation. Welcome to ${newGroup}!`,
					});
				}
				else {
					dispatch({
						type: SET_SNACKBAR_INFO,
						payload:
							`Invitation to join ${newGroup} has been dismissed`,
					});
				}
			})
			.catch((error) => {
				reject(error)
				dispatch({
					type: SET_SNACKBAR_ERROR,
					payload:
						"There was a problem joining this organisation, please contact your organisation admin.",
				});
			});
	});
};

export const verifyEmailOrMobile = (emailOrMobileNumber, token, silent) => (dispatch) => {
	let type = emailOrMobileNumber?.includes('@') ? 'email' : 'phone_number';
	return new Promise((resolve, reject) => {
		axiosInstance
			.post(`/users/accounts/verify/${type}/${emailOrMobileNumber}/${token}`)
			.then((result) => {
				resolve(result.data);
				if (!silent) {
				dispatch({
					type: SET_SNACKBAR_SUCCESS,
					payload: 'Email Verified!',
				});
				}
			})
			.catch((error) => {
				const message = error.response?.data?.errors?.[0]?.message
					|| `There was a problem verifying your ${type==='email' ? 'email' : 'mobile number'}. Please try later.`;
				const code = error.response?.data?.errors?.[0]?.code || null;
				if (!silent) {
					dispatch({
						type: SET_SNACKBAR_ERROR,
						payload: message,
					});
				}
				reject({ message, code });
			});
	});
}

export const checkOTPUsage = (emailOrMobileNumber, silent) => (dispatch) => {
	let type = emailOrMobileNumber?.includes('@') ? 'email' : 'phone_number';
	return new Promise((resolve, reject) => {
		axiosInstance
			.post(`/otp/check_usage`, { [type]: emailOrMobileNumber }).then((result) => {
				resolve(result.data);
				dispatch({
					type: CHECK_OTP_USAGE,
					payload: result.data
				})
			}).catch((error) => {
				const message = error.response?.data?.errors?.[0]?.message
					|| `There was a problem checking OTP usage. Please try later.`;
				const code = error.response?.data?.errors?.[0]?.code || null;
				if (!silent) {
					dispatch({
						type: SET_SNACKBAR_ERROR,
						payload: message,
					});
				}
				reject({ message, code });
		})
	})
}

export const sendOTP = (emailOrMobileNumber, purpose, silent) => (dispatch) => {
	let type = emailOrMobileNumber?.includes('@') ? 'email' : 'phone_number';
	let data = {'purpose': purpose}
	type === 'email' ? data['email'] = emailOrMobileNumber : data['phone_number'] = emailOrMobileNumber
	return new Promise((resolve, reject) => {
		if (!emailOrMobileNumber) reject({ message: 'Email or Mobile Number is required' });
		axiosInstance
			.post(`/otp/${type ==='email'? 'email' : 'sms'}`, data)
			.then((result) => {
				resolve(result.data);
				if (!silent) {
				dispatch({
					type: SET_SNACKBAR_SUCCESS,
					payload: `OTP sent to ${emailOrMobileNumber}`,
				});
				}
			})
			.catch((error) => {
				const message = error.response?.data?.errors?.[0]?.message
					|| `There was a problem sending OTP. Please try later.`;
				const code = error.response?.data?.errors?.[0]?.code || null;
				if (!silent) {
					dispatch({
						type: SET_SNACKBAR_ERROR,
						payload: message,
					});
				}
				reject({ message, code });
			});
	});
}